<!--
 * @Author: wyq
 * @Date: 2021-06-15 11:05:14
 * @LastEditTime: 2021-10-21 15:57:37
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\views\detail.vue
-->
<template>
  <page-container class="page-detail" asideTitle="基础信息编辑">
    <select-form slot="aside" mode="edit" :form.sync="form" @save="updateQuestionBase"></select-form>
    <content-container title="同源试题编辑">
      <div class="same-source-test">
        <ul>
          <li
            v-for="(item,index) in form.questions"
            :key="item.questionId"
            :class="{'index':item.isMotherQuestion,'active':item.questionId==id}"
          >
            <div class="id">ID:{{item.questionId}}</div>
            <div class="type">
              <span class="num">{{index+1}}</span>
              {{item.type|getQuestionType}}
            </div>
            <el-button
              class="preview-btn"
              type="warning"
              size="mini"
              @click="clickPreview(item.questionId)"
            >预览</el-button>
            <el-button
              class="edit-btn"
              type="primary"
              size="mini"
              @click="goEdit(item.questionId)"
            >编辑</el-button>
            <el-button
              class="delete-btn"
              type="danger"
              size="mini"
              @click="clickDelete(item.questionId)"
            >删除</el-button>
          </li>
          <li
            v-if="form.questions&&form.questions.length<9"
            class="add-btn"
            @click="addGroupQuestion"
          >
            <i class="el-icon-plus"></i>
            <p>添加同源题</p>
          </li>
        </ul>
      </div>
    </content-container>
    <content-container title="编辑记录">
      <el-table :data="tableData" header-cell-class-name="header-cell-style">
        <el-table-column label="编辑时间">
          <template slot-scope="scope">
            <div>{{scope.row.editTimeAt|datefmt('YYYY-MM-DD HH:mm:ss')}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="editContent" label="编辑内容"></el-table-column>
        <el-table-column prop="editAccName" label="编辑者"></el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :total="total"
        @current-change="getQuestionHistory"
        hide-on-single-page
      ></el-pagination>
    </content-container>
    <preview-dialog
      :visiable.sync="previewVisiable"
      :grade="grade"
      :subject="subject"
      :id="currentId"
    ></preview-dialog>
  </page-container>
</template>
<script>
import selectForm from '../components/edit/selectForm.vue'
export default {
  components: {
    selectForm,
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      form: {
        grade: null, //年级
        subject: null, //学科
        questionBank: null, //所属题库
        classify: null, //教材试题分类
        labelInfos: [],
        chapterLabels: [],
      },
      motherQuestionId: null,
      currentId: null,
      previewVisiable: false,
    }
  },
  computed: {
    grade() {
      return Number(this.$route.query.grade)
    },
    subject() {
      return Number(this.$route.query.subject)
    },
    id() {
      return Number(this.$route.query.id)
    },
  },
  methods: {
    init() {
      this.getQuestionInfo()
      this.getQuestionHistory()
    },
    getQuestionInfo() {
      let params = {
        grade: this.grade,
        subject: this.subject,
        questionId: this.id,
      }
      this.$service.getQuestionInfo(params).then((res) => {
        const [version, ...knowledgePoints] = res.knowledgePoints
        this.form = {
          grade: this.grade,
          subject: this.subject,
          ...res,
          labelInfos: res.labelInfos ? res.labelInfos : [],
          chapterLabels: res.chapterLabels ? res.chapterLabels : [],
          version,
          knowledgePoints,
        }
        for (let i = 0; i < res.questions.length; i++) {
          if (res.questions[i].isMotherQuestion) {
            this.motherQuestionId = res.questions[i].questionId
            break
          }
        }
      })
    },
    getQuestionHistory() {
      let params = {
        grade: this.grade,
        subject: this.subject,
        questionId: this.id,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      }
      this.$service.getQuestionHistory(params).then((res) => {
        this.tableData = res.questionHistorys ? res.questionHistorys : []
        this.total = res.totalCount
      })
    },
    updateQuestionBase() {
      let params = {
        questionId: this.id,
        ...this.form,
        labels: this.form.labelInfos
          ? this.form.labelInfos.map((i) => i.id)
          : [],
        knowledgePoints: this.form.version
          ? [this.form.version, ...this.form.knowledgePoints]
          : [],
      }
      this.$service.updateQuestionBase(params).then(() => {
        this.$message({
          message: '保存成功！',
          type: 'success',
        })
        this.init()
      })
    },
    addGroupQuestion() {
      this.$router.push({
        name: 'AddGroupQuestion',
        query: {
          id: this.motherQuestionId,
          grade: this.grade,
          subject: this.subject,
        },
      })
    },
    goEdit(id) {
      this.$router.push({
        name: 'EditQuestion',
        query: {
          id: id,
          grade: this.grade,
          subject: this.subject,
        },
      })
    },
    clickDelete(id) {
      this.$confirm('是否确认删除试题？', '删除确认', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        cancelButtonClass: 'el-button--primary is-plain',
        confirmButtonClass: 'el-button--danger',
      })
        .then(() => {
          this.deleteGroupQuestion(id)
        })
        .catch(() => {})
    },
    deleteGroupQuestion(id) {
      let params = {
        grade: this.grade,
        subject: this.subject,
        groupQuestionId: id,
      }
      this.$service.deleteGroupQuestion(params).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!',
        })

        this.$router.replace({
          query: {
            id: this.motherQuestionId,
            grade: this.grade,
            subject: this.subject,
            isMotherQuestion: true,
          },
        })
        this.init()
      })
    },
    clickPreview(id) {
      this.currentId = id
      this.previewVisiable = true
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style lang="scss" scoped>
.page-detail {
  .content-container + .content-container {
    margin-top: 24px;
  }
  .same-source-test {
    border-top: 1px solid #f0f0f0;
    padding-top: 24px;
    & > p {
      display: inline-block;
      width: 100px;
      margin: 0;
      font-size: 16px;
      font-weight: 800;
      color: #333333;
      vertical-align: top;
    }
    ul {
      display: inline-block;
      width: 1000px;
      margin: 0 0 0 -60px;
      padding: 0;
      li {
        display: inline-block;
        position: relative;
        width: 268px;
        height: 100px;
        margin-left: 60px;
        margin-bottom: 40px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.09);
        border-radius: 4px;
        border: 1px solid #d8d8d8;
        overflow: hidden;

        .id {
          position: absolute;
          left: 12px;
          top: 12px;
          font-size: 12px;
          color: #666;
          line-height: 18px;
          z-index: 10;
        }
        .num {
          font-weight: 600;
        }
        .type {
          position: absolute;
          left: 12px;
          top: 32px;
          font-size: 16px;
          font-weight: 800;
          color: #666;
          line-height: 22px;
          z-index: 10;
        }
        .preview-btn {
          position: absolute;
          top: 16px;
          right: 24px;
        }
        .edit-btn {
          position: absolute;
          top: 59px;
          right: 24px;
        }
        .delete-btn {
          position: absolute;
          top: 59px;
          right: 100px;
        }
        .el-button {
          border-radius: 2px;
        }
        &.index {
          // background: linear-gradient(270deg, #2d7cbb 0%, #144686 100%);
          &::after {
            position: absolute;
            left: -43px;
            top: 8px;
            content: '母题';
            width: 160px;
            height: 160px;
            opacity: 0.2;
            border-radius: 50%;
            line-height: 100px;
            text-align: center;
            font-size: 44px;
            font-weight: 800;
            color: #e6e6e6;
            border: 16px solid #e6e6e6;
          }
          .delete-btn {
            display: none;
          }
          // .id,
          // .type {
          //   color: #fff;
          // }
          // .preview-btn {
          //   background-color: inherit;
          //   border-color: #fff;
          // }
          // .edit-btn {
          //   background-color: #fff;
          //   border-color: #fff;
          //   color: #164c8c;
          // }
        }
        &.active {
          border-color: #144686;
          transform: translateY(-8px);
        }
        &.add-btn {
          text-align: center;
          cursor: pointer;

          .el-icon-plus {
            margin-top: 24px;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.45);
          }
          & > p {
            margin-top: 5px;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 22px;
          }
        }
      }
    }
  }
  ::v-deep .header-cell-style {
    background-color: #fafafa;
    color: #333333;
  }
  ::v-deep .cell {
    padding-left: 26px;
  }
  .el-table {
    margin-bottom: 40px;
  }
}
</style>